<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'task-create'"
    v-if="getPermission('task:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Task
        <p
          v-if="pageTitle"
          class="m-0 form-title-create-link pl-2 text-capitalize"
        >
          {{ pageTitle }}
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Task
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="taskForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid v-bar class="create-task-vbar">
          <v-row>
            <v-col md="7">
              <v-row>
                <v-col md="12" class="py-0">
                  <label class="font-weight-700 font-size-16"
                    >Task Preset</label
                  >
                  <v-autocomplete
                    v-model.trim="taskCreate.preset"
                    :items="taskPresetList"
                    dense
                    filled
                    :disabled="pageLoading"
                    color="cyan"
                    item-color="cyan"
                    item-text="title"
                    item-value="id"
                    label="Preset"
                    solo
                    flat
                    clearable
                    hide-details
                    v-on:change="update_preset()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Task Preset Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col md="12" class="py-0 mt-4">
                  <label class="font-weight-700 font-size-16"
                    >Task Subject</label
                  >
                  <v-text-field
                    v-model.trim="taskCreate.subject"
                    dense
                    filled
                    label="Subject"
                    solo
                    flat
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    :rules="[
                      validateRules.required(
                        taskCreate.subject,
                        'Task Subject'
                      ),
                      validateRules.minLength(
                        taskCreate.subject,
                        'Task Subject',
                        1
                      ),
                      validateRules.maxLength(
                        taskCreate.subject,
                        'Task Subject',
                        100
                      ),
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0">
                  <editor v-model="taskCreate.description" />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Task details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Task number</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                      v-else
                      v-model.number="taskCreate.barcode"
                      dense
                      filled
                      label="Total Project Cost"
                      solo
                      flat
                      readonly
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                  <td class="font-size-16 pb-2">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference#</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="taskCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          taskCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          taskCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Lead</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Lead"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      v-model.trim="taskCreate.lead"
                      :items="taskLeadList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="first_name"
                      item-value="id"
                      label="Lead"
                      solo
                      flat
                      clearable
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Lead Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <template v-if="!taskCreate.lead">
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Customer</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Customer"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-autocomplete
                        v-model.trim="taskCreate.customer"
                        :items="taskCustomerList"
                        dense
                        filled
                        :disabled="isCustomerDisabled || pageLoading"
                        color="cyan"
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        label="Customer"
                        solo
                        flat
                        clearable
                        hide-details
                        v-on:change="getProjects()"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Customer Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Project</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Project"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-autocomplete
                        v-model.trim="taskCreate.project"
                        :items="taskProjectList"
                        dense
                        filled
                        :disabled="isProjectDisabled || pageLoading"
                        color="cyan"
                        item-color="cyan"
                        item-text="name"
                        item-value="id"
                        label="Project"
                        solo
                        flat
                        clearable
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Project Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <!--
                  <template v-if="project">
                    <tr>
                      <td valign="middle" class="font-size-16 pb-2" width="200">
                        Total Project Cost
                      </td>
                      <td
                        colspan="2"
                        class="pb-4"
                        content="Total Project Cost"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          v-model.number="project.cost"
                          dense
                          filled
                          prefix="$"
                          type="number"
                          min="0"
                          max="999"
                          label="Total Project Cost"
                          solo
                          flat
                          readonly
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle" class="font-size-16 pb-2" width="200">
                        Pending Project Cost
                      </td>
                      <td
                        colspan="2"
                        class="pb-4"
                        content="Pending Project Cost"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          v-model.number="project.total_pending"
                          dense
                          filled
                          prefix="$"
                          type="number"
                          min="0"
                          max="999"
                          readonly
                          label="Pending Project Cost"
                          solo
                          flat
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                    </tr>
                  </template>-->
                  <tr v-if="false">
                    <td class="font-size-16 pb-2" width="200">Contractors</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Contractors"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="taskContractorList"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="taskCreate.contractors"
                        label="Contractors"
                        solo
                        flat
                        item-color="cyan"
                        item-text="barcode"
                        item-value="id"
                        hide-details
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span
                              >{{ item.barcode }} -
                              {{ item.display_name }}</span
                            >
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text font-size-12"
                          >
                            (+{{ taskCreate.contractors.length - 1 }} others)
                          </span>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Contractor Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </template>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Members</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Members"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="memberList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="taskCreate.members"
                      label="Members"
                      solo
                      flat
                      item-color="cyan"
                      item-text="full_name"
                      item-value="id"
                      hide-details
                      multiple
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.full_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text font-size-12"
                        >
                          (+{{ taskCreate.members.length - 1 }} others)
                        </span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Member Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Task Cost</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Task Cost"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.number="taskCreate.task_cost"
                      dense
                      filled
                      type="number"
                      min="0"
                      max="999"
                      label="Task Cost"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="limitDecimal($event, taskCreate.task_cost)"
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Hourly Cost</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Hourly Cost"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.number="taskCreate.hourly_rate"
                      dense
                      filled
                      type="number"
                      min="0"
                      max="999"
                      label="Hourly Cost"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, taskCreate.hourly_rate)
                      "
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Priority</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Priority"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      v-model.trim="taskCreate.priority"
                      :items="taskPriorityList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Priority"
                      solo
                      flat
                      return-object
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Priority Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Planned Start Date
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Planned Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :defaultDate="defaultStartDate()"
                      :pageLoading.sync="pageLoading"
                      solo
                      :min-date="minDate"
                      :max-date="taskCreate.due_date"
                      placeholder="Planned Start Date"
                      v-on:update:date-picker="setStartDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Planned Due Date
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Planned Due Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :min-date="taskCreate.start_date"
                      :defaultDate="defaultDueDate()"
                      :pageLoading.sync="pageLoading"
                      solo
                      placeholder="Planned Due Date"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Repeat every</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Recurrence"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-select
                      v-model.trim="taskCreate.recurrence"
                      :items="taskRecurrenceList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Recurrence"
                      solo
                      flat
                      return-object
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Recurrence Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
                <template v-if="false && taskCreate.recurrence">
                  <tr v-if="taskCreate.recurrence.custom">
                    <td class="font-size-16 pb-2" width="200">&nbsp;</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Custom Recurrence"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-layout>
                        <v-flex>
                          <v-text-field
                            v-model.number="taskCreate.duration"
                            dense
                            filled
                            type="number"
                            min="0"
                            max="999"
                            label="Duration"
                            solo
                            flat
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-select
                            v-model.trim="taskCreate.duration_type"
                            :items="taskDurationTypeList"
                            dense
                            filled
                            :disabled="pageLoading"
                            color="cyan"
                            item-color="cyan"
                            item-text="value"
                            item-value="id"
                            label="Duration Type"
                            solo
                            flat
                            return-object
                            hide-details
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Duration Type Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Total Cycles</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Total Cycles"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model.number="taskCreate.total_cycle"
                        dense
                        filled
                        type="number"
                        min="0"
                        max="30"
                        v-mask="'##'"
                        label="Total Cycles"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                </template>
              </table>
            </v-col>
            <v-col md="12">
              <AttachmentWithName
                v-on:file:updated="updateAttachment"
                :label="true"
                allowUpload
              ></AttachmentWithName>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="task"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-create",
  title: "Create Task",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      backForce: false,
      barcodeSetting: new Object(),
      taskDuplicate: 0,
      pageTitle: null,
      project: null,
      memberList: [],
      taskPresetList: [],
      taskCreate: new Object({
        lead: null,
        customer: null,
        preset: null,
        project: null,
        contractors: [],
        subject: null,
        description: null,
        members: [],
        barcode: null,
        reference: null,
        task_cost: null,
        //hourly_rate: null,
        priority: null,
        recurrence: null,
        duration: null,
        duration_type: null,
        total_cycle: null,
        start_date: null,
        attachments: null,
        due_date: null,
      }),
      isCustomerDisabled: false,
      isProjectDisabled: false,
      taskLeadList: [],
      taskCustomerList: [],
      taskContractorList: [],
      taskProjectList: [],
      taskPriorityList: [
        {
          id: 1,
          value: "High",
        },
        {
          id: 2,
          value: "Medium",
        },
        {
          id: 3,
          value: "Low",
        },
        {
          id: 0,
          value: "None",
        },
      ],
      taskDurationTypeList: [
        {
          id: 1,
          value: "Day(s)",
        },
        {
          id: 2,
          value: "Week(s)",
        },
        {
          id: 3,
          value: "Month(s)",
        },
        {
          id: 4,
          value: "Year(s)",
        },
      ],
      taskRecurrenceList: [
        {
          id: 1,
          custom: false,
          value: "Week",
        },
        {
          id: 2,
          custom: false,
          value: "2 Weeks",
        },
        {
          id: 3,
          custom: false,
          value: "1 Months",
        },
        {
          id: 4,
          custom: false,
          value: "2 Months",
        },
        {
          id: 5,
          custom: false,
          value: "3 Months",
        },
        {
          id: 6,
          custom: false,
          value: "6 Months",
        },
        {
          id: 7,
          custom: false,
          value: "1 Year",
        },
        {
          id: 8,
          custom: true,
          value: "Custom",
        },
      ],
      minDate: new Date().toISOString().substr(0, 10),
    };
  },
  watch: {
    "taskCreate.project"(param) {
      if (param) {
        this.project =
          this.lodash.find(this.taskProjectList, { id: param }) || {};
        this.getContracts(param);
      } else {
        this.project = null;
      }
    },
  },
  components: {
    DatePicker,
    BarcodeSetting,
    CreateUpdateTemplate,
    editor: TinyMCE,
    AttachmentWithName,
  },
  methods: {
    update_preset() {
      const preset = this.taskCreate.preset;
      const db_preset = this.lodash.find(this.taskPresetList, { id: preset });
      if (db_preset) {
        this.taskCreate.subject = db_preset.title;
      }
    },
    getContracts(project) {
      this.$store
        .dispatch(QUERY, {
          url: "contractor/all",
          data: { type: "project", type_id: project },
        })
        .then(({ data }) => {
          this.taskContractorList = data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateAttachment(param) {
      this.taskCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/options",
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          _this.taskCreate.barcode = data.barcode;
          _this.taskContractorList = data.contractors;
          _this.memberList = data.members;
          _this.taskPresetList = data.presets;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate(action) {
      const _this = this;
      if (!_this.$refs.taskForm.validate()) {
        return false;
      }

      let status = 4;
      if (action == "mark_as_testing") {
        status = 2;
      }
      if (action == "mark_as_inprogress") {
        status = 3;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      if (_this.taskCreate.lead) {
        //_this.taskCreate.project = null;
        _this.taskCreate.customer = null;
      }

      if (_this.taskCreate.customer) {
        _this.taskCreate.lead = null;
      }

      let formData = new Object({
        preset: _this.taskCreate.preset,
        reference: _this.taskCreate.reference,
        subject: _this.taskCreate.subject,
        lead: _this.taskCreate.lead,
        customer: _this.taskCreate.customer,
        project: _this.taskCreate.project,
        contractors: _this.taskCreate.contractors,
        description: _this.taskCreate.description,
        task_cost: _this.taskCreate.task_cost,
        //hourly_rate: _this.taskCreate.hourly_rate,
        start_date: _this.taskCreate.start_date,
        due_date: _this.taskCreate.due_date,
        recurrence: _this.taskCreate.recurrence
          ? _this.taskCreate.recurrence.id
          : null,
        duration:
          _this.taskCreate.recurrence && _this.taskCreate.recurrence.custom
            ? _this.taskCreate.duration
            : null,
        duration_type:
          _this.taskCreate.recurrence && _this.taskCreate.recurrence.custom
            ? _this.taskCreate.duration_type
              ? _this.taskCreate.duration_type.id
              : null
            : null,
        total_cycle: _this.taskCreate.total_cycle,
        status: status,
        priority: _this.taskCreate.priority
          ? _this.taskCreate.priority.id
          : null,
        attachments: _this.taskCreate.attachments,
        members: _this.taskCreate.members,
      });

      _this.$store
        .dispatch(POST, {
          url: "tasks",
          data: formData,
        })
        .then(({ data }) => {
          _this.backForce = true;
          const { route, route_id } = this.$route.query;
          if (route && route_id) {
            _this.$router.push(
              _this.getDefaultRoute(route, {
                params: {
                  id: route_id,
                },
                query: {
                  tab: "task",
                },
              })
            );
          } else {
            _this.$router.push(
              _this.getDefaultRoute("task.detail", {
                params: {
                  id: data.id,
                },
              })
            );
          }
        })
        .catch((error) => {
          _this.backForce = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    setStartDate(param) {
      this.taskCreate.start_date = param;
    },
    setDueDate(param) {
      this.taskCreate.due_date = param;
    },
    getTask() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.taskDuplicate,
        })
        .then(({ data }) => {
          _this.taskCreate.subject = data.subject;
          _this.taskCreate.description = data.description;
          _this.taskCreate.reference = data.reference;
          _this.taskCreate.task_cost = data.task_cost;
          _this.taskCreate.hourly_rate = data.hourly_rate;
          _this.taskCreate.priority = data.priority;
          _this.taskCreate.contractors = data.contractors
            ? data.contractors.map((row) => row.contractor)
            : [];
          _this.taskCreate.recurrence = data.recurrence;
          _this.taskCreate.duration = data.duration;
          _this.taskCreate.duration_type = data.duration_type;
          _this.taskCreate.total_cycle = data.total_cycle;
          _this.taskCreate.start_date = data.start_date;
          _this.taskCreate.attachments = [];
          _this.taskCreate.due_date = data.due_date;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLeads() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "lead-listing" })
        .then(({ data }) => {
          _this.taskLeadList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomer() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer-list" })
        .then(({ data }) => {
          _this.taskCustomerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProjects() {
      const _this = this;
      const { customer } = _this.taskCreate;
      if (customer) {
        _this.$store
          .dispatch(GET, { url: `customer/${customer}/projects` })
          .then(({ data }) => {
            _this.taskProjectList = data;
            if (_this.taskCreate.project) {
              _this.project =
                _this.lodash.find(data, { id: _this.taskCreate.project }) ||
                null;
            } else {
              _this.project = null;
            }
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
    defaultStartDate() {
      if (this.taskCreate.start_date) {
        return this.taskCreate.start_date;
      }
      this.taskCreate.start_date = new Date().toISOString().substr(0, 10);
      return this.taskCreate.start_date;
    },
    defaultDueDate() {
      if (this.taskCreate.due_date) {
        return this.taskCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      this.taskCreate.due_date = today.toISOString().substr(0, 10);
      return this.taskCreate.due_date;
    },
  },
  mounted() {
    const _this = this;
    _this.getCustomer();
    _this.getLeads();
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Task", route: "task" },
      { title: "Create" },
    ]);

    _this.taskDuplicate = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );

    _this.pageTitle =
      _this.lodash.toString(_this.$route.query["page-title"]) || null;

    _this.taskCreate.project =
      _this.lodash.toSafeInteger(_this.$route.query.project) || null;
    if (_this.taskCreate.project > 0) {
      _this.isProjectDisabled = true;
    }

    _this.taskCreate.customer =
      _this.lodash.toSafeInteger(_this.$route.query.customer) || null;
    if (_this.taskCreate.customer > 0) {
      _this.isCustomerDisabled = true;
    }
    _this.getProjects();

    if (!_this.taskCreate.customer) {
      _this.taskCreate.lead =
        _this.lodash.toSafeInteger(_this.$route.query.lead) || null;
    }
    //console.log(_this.taskCreate.project);
    /* if (_this.taskCreate.lead) {
      _this.taskCreate.project = null;
      _this.taskCreate.customer = null;
    } */

    if (_this.taskDuplicate > 0) {
      _this.getTask();
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
